<template>
  <div class="footer">
    <div class="link-box" v-for="item in navTab" :key="item.key">
      <router-link :to="item.link">
        <img v-if="item.link === $route.path" :src="item.activeIco" alt="" />
        <img v-else :src="item.ico" alt="" />
        <div class="name">{{ item.str }}</div>
      </router-link>
    </div>
  </div>
</template>
<script>
import { routerLink } from '@/common/js';
export default {
  data() {
    return {
      navTab: [],
      activeName: '',
    };
  },
  created() {
    let linkkey = [
        { name: '任务进度', key: 'progress', ico: require('@/assets/image/mobile/icon_jdgl_bl@2x.png') },
        { name: '日事清', key: 'daily', ico: require('@/assets/image/mobile/icon_rsq_bl@2x.png') },
        { name: '沟通', key: 'communicate', ico: require('@/assets/image/mobile/icon_chat@2x.png') },
      ],
      linkarr = [];
    linkkey.forEach(v => {
      routerLink.forEach(v1 => {
        v1.list.forEach(v2 => {
          if (v.key == v2.key) {
            v2.str = v.name;
            v2.ico = v.ico;
            linkarr.push(v2);
          }
        });
      });
    });
    this.navTab = linkarr;
    this.navTab.push({
      key: 'my',
      str: '我的',
      link: '/my',
      ico: require('@/assets/image/mobile/icon_wp_bl@2x.png'),
      activeIco: require('@/assets/image/mobile/icon_wp_bla@2x.png'),
    });
  },
  methods: {
    handleLinkTo(nav) {
      console.log(nav);
      if (nav.link) {
        this.$router.push(nav.link);
      }
    },
  },
};
</script>
<style scoped lang="less">
.footer {
  width: 32rem;
  height: 4.18rem;
  padding-top: 0.51rem;
  background: #ffffff;
  opacity: 1;
  display: flex;
  flex-direction: row;
}

.link-box {
  height: 100%;
  flex: 1 1 100%;
  text-align: center;
  img {
    width: 1.88rem;
    height: 1.88rem;
  }
  .name {
    font-size: 0.85rem;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 1.19rem;
    color: #8590b3;
  }
}
.router-link-active {
  .name {
    color: #13bfff;
  }
}
</style>
