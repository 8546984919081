<template>
  <div id="app">
    <template v-if="isLogin">
      <div id="main" ref="main" v-if="$store.state.isLogin">
        <keep-alive>
          <transition name="my-sheet">
            <router-view></router-view>
          </transition>
        </keep-alive>
      </div>
      <Footers v-show="$store.state.mobile.switchFoot" />
    </template>
    <router-view v-else name="login"></router-view>
  </div>
</template>
<script>
import Footers from '@/components/mobile/Footer';
import { postTaskUser, postUserInfoLis } from '@/service/help';
import Bus from '@/assets/js/Bus';

export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {
    //如果登录失效，获取不到，如果登录成功，store那边会重新set
    postUserInfoLis().then(r => {
      this.$store.commit('set', { type: 'userInfo', data: r.data.data });
    });
  },
  created() {
    this.$store.commit('set', { type: 'Bus', data: Bus });
    postTaskUser({ id: '' }).then(res => {
      this.$store.commit('set', { type: 'member', data: res.data.data });
    });
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    },
  },
  components: {
    Headers,
    Footers,
  },
};
</script>
<style lang="less">
@import '~@/assets/style/mobile/mobile.less';
</style>
